/* stylelint-disable declaration-no-important */
html,
body {
  width: 100%;
}

* {
  text-shadow: none !important;
  box-shadow: none !important;
  -webkit-print-color-adjust: exact;
}

a {
  text-decoration: underline !important;
}

a[href^=http]::after {
  content: " (" attr(href) ")";
}

svg {
  fill: #000000 !important;
}

img {
  max-width: 100%;
  height: auto;
}

a,
table,
pre,
blockquote {
  page-break-inside: avoid;
}

h1,
h2,
h3,
h4,
h5,
h6,
img,
svg,
figure {
  page-break-after: avoid;
  page-break-inside: avoid;
}

ul,
ol,
dl {
  page-break-before: avoid;
}

nav,
form,
iframe,
.u-no-print,
.btn {
  display: none;
}