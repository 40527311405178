/* stylelint-disable declaration-no-important */
@use 'base/variables';

html,
body {
	width: 100%;
	// background: none !important;
}
* {
	text-shadow: none !important;
	box-shadow: none !important;
	-webkit-print-color-adjust: exact; // force background show

	// background: none !important; // optional
	// color: variables.$color-black !important; // optional: convert everything to BW, save print colors
	// font-family: variables.$font-family-base !important; // optional

	// Images, vectors and such
	// filter: gray(); // optional: convert everything to BW, save print colors
	// filter: grayscale(100%); // optional: convert everything to BW, save print colors
}
a {
	text-decoration: underline !important; // optional
}

// print URL next to the link. Optional
a[href^='http']::after {
	content: ' (' attr(href) ')';
}
svg {
	fill: #000000 !important;
}
img {
	max-width: 100%;
	height: auto;
}

// Defining all page breaks
a,
table,
pre,
blockquote {
	page-break-inside: avoid;
}
h1,
h2,
h3,
h4,
h5,
h6,
img,
svg,
figure {
	page-break-after: avoid;
	page-break-inside: avoid;
}
ul,
ol,
dl {
	page-break-before: avoid;
}

// Hide redundants
nav,
form,
iframe,
.u-no-print, // Util class for clients
.btn {
	display: none;
}
